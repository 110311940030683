import React, { useRef } from "react";
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ServiceHero from "../../modules/hero/service-hero";
import TwoColumnText from "../../components/two-column-text";
import FeaturedWork from "../../components/featured-work";
import Aassociate from "../../images/services/Case-Studies-Aassociate.jpeg";
import AVNT from "../../images/services/Case-Studies-AVNT.jpeg";
import FooterMapDetails from "../../components/footer/footer-map-details";

const featured = [
  {
    title: "associate.com",
    coverImage: Aassociate,
    link: "associate",
  },
  {
    title: "AVNT",
    coverImage: AVNT,
    link: "avnt",
  },
];

const Description = () => {
  return (
    <div>
      <p>
        <b>Ideation</b>
      </p>
      <p>
        Your ideas, our ideas, all the ideas! We want to be able to gather
        everyone's ideas, problems, and goals to help us create a roadmap to
        lead to success.
      </p>
      <p>
        <b>Wireframing</b>
      </p>
      <p>
        To make sure we're on the right track, we start with low fidelity
        wireframes and follow that up with user testing to make sure it is the
        right direction.
      </p>
      <p>
        <b>UI/UX</b>
      </p>
      <p>
        After finalizing the high level flow with our wireframes, we then take
        it to next level to create high fidelity mockups with animated
        prototypes. Then we test again!
      </p>
      <p>
        <b>A/B Testing</b>
      </p>
      <p>
        We believe that the first iteration can always be improved on. We
        believe in testing out multiple designs in order to get the desired
        behavior that is wanted.
      </p>
    </div>
  );
};

const ServiceTemplate = ({ location }) => {
  const pageLayoutRef = useRef();

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };


  const seo = {
    pageUrl: "/services/user-experience-design",
    pageTitle: "User experience and design | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "User experience and design | Vello | A full-service creative agency.",
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ServiceHero
          title={"User Experience & Design"}
          pageLayoutRef={pageLayoutRef}
        />
        <TwoColumnText
          title="Overview"
          text={
            "Our engagement model is iterative and collaborative, not just bringing you along but working together to create work that delivers results. We think about long-term opportunities while helping you solve the immediate needs of today. From KPI definition and experience vision to rapid design, prototyping, and deployment, we’ve built our agency to be a true partner for our clients and we’d love to work with you to create what’s next."
          }
        />
        <TwoColumnText title="Our Process">
          <Description />
        </TwoColumnText>
        <FeaturedWork
          title="Featured Work"
          featuredWork={featured}
          pageLayoutRef={pageLayoutRef}
        />

        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default ServiceTemplate;
